<template>
  <!--About-->
  <div class="about">
   <!--  <h2 class="title">This is an about page</h2> -->
    <div class="card shadow-sm">
      <div class="card-body">
         <p v-html="blogInfo.blogInfo"></p>
      </div>
    </div>
  </div>
  <!--/About-->
</template>
<script>
// @ is an alias to /src
export default {
  data(){
    return {
      blogInfo:[],
    }
  },
  beforeMount(){
    this.getBlogInfo();
  },
  watch:{
        $route (to, from){ this.getBlogInfo(); }
    },
  
  methods:{
    getBlogInfo(){
      let menuId=this.$route.params.contentId;
      this.$http.get("get/blog/info",{params:{dataId:menuId}})
       .then(response=>{
        if(response.data.msgFlag==true)
          this.blogInfo=response.data.dataInfo;
       })
       .catch(error=>{console.dir(error)})
       .finally();
    }
  }
};
</script>
